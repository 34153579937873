@font-face {
  font-family: 'filerobot-image-editor-font';
  src:  url('https://cdn.scaleflex.it/plugins/filerobot-image-editor/assets/fonts/filerobot-image-editor-font/v3/filerobot-image-editor-font.eot?ua0hzun3');
  src:  url('https://cdn.scaleflex.it/plugins/filerobot-image-editor/assets/fonts/filerobot-image-editor-font/v3/filerobot-image-editor-font.eot?ua0hzun3#iefix') format('embedded-opentype'),
  url('https://cdn.scaleflex.it/plugins/filerobot-image-editor/assets/fonts/filerobot-image-editor-font/v3/filerobot-image-editor-font.ttf?ua0hzun3') format('truetype'),
  url('https://cdn.scaleflex.it/plugins/filerobot-image-editor/assets/fonts/filerobot-image-editor-font/v3/filerobot-image-editor-font.woff?ua0hzun3') format('woff'),
  url('https://cdn.scaleflex.it/plugins/filerobot-image-editor/assets/fonts/filerobot-image-editor-font/v3/filerobot-image-editor-font.svg?ua0hzun3#filerobot-image-editor-font') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="sf-i-"], [class*=" sf-i-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'filerobot-image-editor-font' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sf-i-watermark:before {
  content: "\e918";
}
.sf-i-attention:before {
  content: "\e917";
}
.sf-i-reset:before {
  content: "\e912";
}
.sf-i-rotate-right:before {
  content: "\e913";
}
.sf-i-flip-vertically:before {
  content: "\e914";
}
.sf-i-rotate-left:before {
  content: "\e915";
}
.sf-i-flip-horizontally:before {
  content: "\e916";
}
.sf-i-arrows-back:before {
  content: "\e905";
}
.sf-i-arrows-forward:before {
  content: "\e906";
}
.sf-i-filter:before {
  content: "\e904";
}
.sf-i-minus:before {
  content: "\e910";
}
.sf-i-plus:before {
  content: "\e911";
}
.sf-i-effects:before {
  content: "\e900";
}
.sf-i-crop:before {
  content: "\e901";
}
.sf-i-focus-point:before {
  content: "\e919";
}
.sf-i-rotation-left:before {
  content: "\e902";
}
.sf-i-mirror-vertical:before {
  content: "\e903";
}
.sf-i-resize:before {
  content: "\e907";
}
.sf-i-rotation-right:before {
  content: "\e908";
}
.sf-i-mirrot-horizontal:before {
  content: "\e909";
}
.sf-i-adjust:before {
  content: "\e90a";
}
.sf-i-orientation:before {
  content: "\e90b";
}
.sf-i-cross:before {
  content: "\e90c";
}
.sf-i-point-orientation:before {
  content: "\e90d";
}
.sf-i-link:before {
  content: "\e90e";
}
.sf-i-without-link:before {
  content: "\e90f";
}